import React from "react";
import Layout from "../components/layout";

export interface ContactPageProps {}

const ContactPage: React.FC<ContactPageProps> = props => {
  return (
    <Layout>
      <h2>
        <span>Innovación</span>
        <br />a tu alcance
      </h2>
    </Layout>
  );
};

export default ContactPage;
